import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Divider from 'fe-design-base/molecules/Divider';

import { centsToDollar } from 'util/formatter';

interface PriceSummaryProps {
  amountInCents: number;
  salesTax: number;
}

const PriceSummary: React.FC<PriceSummaryProps> = ({
  amountInCents,
  salesTax,
}) => {
  const totalAmountInCents = amountInCents + salesTax * 100;

  return (
    <Box mt={32}>
      <Box>
        <Text variant="heading3" i18n="biller.purchase_method_module.summary" />
      </Box>
      <Box mt={24} gap={16} column>
        <Box row spacebetween>
          <Text
            variant="body"
            color="mono700"
            i18n="biller.purchase_method_module.base_fee"
          />

          <Text variant="body" color="mono900">
            ${centsToDollar(amountInCents)}
          </Text>
        </Box>
        <Box row spacebetween>
          <Text
            variant="body"
            color="mono700"
            i18n="biller.purchase_method_module.sales_tax"
          />
          <Text variant="body" color="mono900">
            ${salesTax}
          </Text>
        </Box>
      </Box>
      <Box mt={24}>
        <Divider color="mono300" />
      </Box>
      <Box row spacebetween mt={24}>
        <Text
          variant="bodyBold"
          color="mono900"
          i18n="biller.purchase_method_module.total"
        />

        <Text variant="bodyBold" color="mono900">
          ${centsToDollar(totalAmountInCents)}
        </Text>
      </Box>
    </Box>
  );
};

export default PriceSummary;
