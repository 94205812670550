import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Button from 'fe-design-base/molecules/Button';

import { FooterProps } from './types';

export const Footer = ({
  isLoading,
  buttonProps,
  onClickPrimary,
  primaryCtaText,
  primaryUxElement,
  onClickSecondary,
  secondaryCtaText,
  secondaryUxElement,
}: FooterProps) => (
  <Box row hright gap={16} mt={32}>
    {onClickSecondary && (
      <Button
        variant="secondary"
        isLoading={isLoading}
        onClick={onClickSecondary}
        uxElement={secondaryUxElement}
        {...buttonProps}
      >
        {String(secondaryCtaText)}
      </Button>
    )}

    {onClickPrimary && (
      <Button
        variant="primary"
        isLoading={isLoading}
        onClick={onClickPrimary}
        uxElement={primaryUxElement}
      >
        {String(primaryCtaText)}
      </Button>
    )}
  </Box>
);

export default Footer;
