import { createSelector } from 'reselect';

import { getActiveTipPolicyId } from 'selectors/session';

export const getLocationEligible = state =>
  state.getIn(['tipPoolingPolicies', 'locationEligible']);

export const getCurrentPayrollPeriod = state =>
  state.getIn(['tipPoolingPolicies', 'currentPayrollPeriod']);

export const getPreviousPayrollPeriod = state =>
  state.getIn(['tipPoolingPolicies', 'previousPayrollPeriod']);

export const getLocationRoles = state =>
  state.getIn(['tipPoolingPolicies', 'locationRoles']);

export const getDataIsLoading = state =>
  state.getIn(['tipPoolingPolicies', 'isLoading']);

export const getTipPoolingPolicies = state =>
  state.getIn(['tipPoolingPolicies', 'tipPoolingPolicies']);

export const showCreatedModal = state =>
  state.getIn(['tipPoolingPolicies', 'showCreatedModal']);

export const showTipPoolDisabledAlert = state =>
  state.getIn(['tipPoolingPolicies', 'showTipPoolDisabledAlert']);

export const showTipPoolEnabledAlert = state =>
  state.getIn(['tipPoolingPolicies', 'showTipPoolEnabledAlert']);

export const showTipPoolUpdatedAlert = state =>
  state.getIn(['tipPoolingPolicies', 'showTipPoolUpdatedAlert']);

export const getTipPoolEffectiveDate = state =>
  state.getIn(['tipPoolingPolicies', 'tipPoolEffectiveDate']);

export const getShowNewRoleAddedSuccessAlert = state =>
  state.getIn(['tipPoolingPolicies', 'showNewRoleAddedSuccessAlert']);

export const getIsSubscribedToTipPooling = state =>
  state.getIn(['tipPoolingPolicies', 'subscribed']);

export const getTipPoolingPrice = state =>
  state.getIn(['tipPoolingPolicies', 'price']);

export const getLocationEligibleForPos = state =>
  state.getIn(['tipPoolingPolicies', 'locationEligibleForPos']);

export const getActiveTipPolicy = createSelector(
  getTipPoolingPolicies,
  getActiveTipPolicyId,
  (tipPoolingPolicies, activeTipPolicyId) =>
    tipPoolingPolicies?.find(policy => policy.id === activeTipPolicyId)
);

export const getTipPolicyById = createSelector(
  getTipPoolingPolicies,
  tipPoolingPolicies => tipId => {
    if (!tipPoolingPolicies) return null;
    if (tipPoolingPolicies.toJS)
      tipPoolingPolicies = tipPoolingPolicies?.toJS();
    return tipPoolingPolicies?.find(policy => policy.id === tipId);
  }
);
